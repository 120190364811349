.home {
  height: 100vh;
  background-image: url("./images/background.jpg");

  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    flex: auto;
    width: 100vw;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      font-size: 82px;
    }

    .content-link {
      width: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content-p {
      padding-left: 40px;
      p {
        display: flex;
        flex-direction: column;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
      }
    }
    .content-link {
      button {
        height: 67px;
        width: 250px;
        background-color: #0b84ec;
        border: none;
        font-size: 21px;
        font-family: Arial, sans-serif;
        font-weight: 600;
        color: white;
        cursor: pointer;
        &:active {
          background-color: white;
          color: #0b84ec;
        }
      }
    }
  }
}
