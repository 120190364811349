.exp-card {
  background-color: white;
  height: auto;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0px;

  .programs {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
      padding: 10px 10px;
    }
  }
  p {
    font-weight: 600;
    text-align: center;
  }
}
.exp {
  height: auto;
}
.comp-exp {
  background-color: white;
  height: auto;
  width: 50vw;
  display: flex;
}
.exp-rivn {
  margin-top: 20px;
}
.work-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-style: italic;
  }
}
.exp-content {
  display: flex;
  flex-direction: column;

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 45px;
    button {
      margin: 0px 40px;
      padding: 10px;
      font-size: 18px;
      cursor: pointer;
      background-color: #393e41;
      border: none;
      outline: 0;
      border-radius: 5px;
      &:active {
        background-color: $site-black;
        color: #393e41;
      }

      &:hover {
        border: 1px solid $site-gold;
      }
    }
  }
}
