.main {
  height: 100vh;
  background-image: url("./images/background.jpg");

  display: flex;
  flex-direction: column;
  align-items: center;
}

.edu-content {
  background-color: white;
  height: auto;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;

  .bottega-img {
    padding: 20px 0px;
  }
  .bottega-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
    }
    p {
      font-style: italic;
    }
  }
  i {
    font-size: 55px;
    padding: 10px 0px;
  }
}
