@import "./variables.scss";
@import "./home.scss";
@import "./navbar.scss";
@import "./contact.scss";
@import "./education.scss";
@import "./experience.scss";
@import "./about.scss";
@import "./queires.scss";
body {
  margin: 0px;
}
