.contact {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  background-image: url("./images/background.jpg");
  .navbar {
    a {
      height: 50px;
    }
  }
  .contact-content {
    display: flex;
    align-items: center;
    background-image: url("./images/background.jpg");
    background-size: contain;
  }
  .pic {
    width: 50vw;
    height: 91.9vh;
    display: flex;
    align-items: center;
    background-image: url("./images/profile_pic.jpg");
    background-size: cover;

    // img {
    //   width: inherit;
    //   height: 80vh;
    //   object-fit: contain;
    // }
  }
  .info {
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
      transition: ease-in-out 0.18s;
      height: 70px;
      &:hover {
        letter-spacing: 1.5px;
        border-bottom: 1.5px solid $site-gold;
      }
    }
    h1 {
      color: white;
      i {
        padding-right: 10px;
        color: $site-gold;
      }
    }
  }
}
